var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content1"},[_c('div',{staticClass:"all"},[_c('div',{staticClass:"table"},[_c('el-table',{ref:"tableList",staticStyle:{"width":"100%","margin":"15px 0"},attrs:{"data":_vm.buildings,"border":"","height":"calc(100vh - 184px)","stripe":true,"header-cell-style":{
            color: '#333',
            fontFamily: 'MicrosoftYaHeiUI',
            fontSize: '14px',
            fontWeight: 900,
            background: '#f8f8f9',
            textAlign:'left',
          }}},[_c('el-table-column',{attrs:{"type":"index","label":"序号","width":"70","index":((_vm.searchBox.current-1)*_vm.searchBox.size)+1}}),_c('el-table-column',{attrs:{"prop":"updateModular","label":"编辑模块","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"createBy","label":"编辑人","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"createTime","label":"编辑时间","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"createBy","label":"操作","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('el-button',{staticStyle:{"cursor":"pointer"},attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.examine(row)}}},[_vm._v(" 查看 ")])]}}])})],1),_c('el-pagination',{attrs:{"background":"","layout":"total, sizes, prev, pager, next, jumper","total":_vm.total,"page-sizes":[10, 30, 50, 100],"page-size":_vm.searchBox.size,"current-page":_vm.searchBox.current},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }